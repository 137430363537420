@font-face {
  font-family: 'OutfitRegular';
  src: url(../../public/outfit/fonts/otf/Outfit-Regular.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'OutfitBold';
  src: url(../../public/outfit/fonts/otf/Outfit-Bold.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
